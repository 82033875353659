<template>
	<div id="deliveryDetail">
		<page-delivery-detail :is-shop="0"></page-delivery-detail>
	</div>
</template>

<script>
import pageDeliveryDetail from '@/components/layout/land/page-delivery-detail.vue'
import { onBeforeRouteLeave } from 'vue-router'
import store from '@/store'
export default{
	components:{
		pageDeliveryDetail
	},
	setup(){
		onBeforeRouteLeave((to,from,next)=>{
			if( to.path == '/land/delivery'){
				store.dispatch("setKeepPage",{name:to.name,type:1})
			}else{
				store.dispatch("setKeepPage",{name:to.name,type:2})
			}
			next()
		})
	}
	
}
</script>

<style lang="scss">
</style>
